import axios from '../plugins/axios.js'

class TargetService {

	updateTarget(targetId, data) {
		return axios.put(`/target/${targetId}`, data);
	}

	softDeleteTarget(targetId) {
		return axios.delete(`/target/${targetId}`);
	}

}

export default new TargetService();