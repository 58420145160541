<template>
	<b-container v-if="$checkPermission('view-target')" fluid id="target">
		<cg-modal :data="data" @update="updateTarget"></cg-modal>

		<cg-loader :display="inProgress"></cg-loader>

		<cg-localized-alert :alertVariant="alertVariant" ref="cgLocAlert" :alertMessage="alertMessage"
			:alertParams="alertParams" :dismissSecs="dismissSecs"></cg-localized-alert>

		<div class="px-4 mb-4">
			<b-card-title class="mb-4">{{ $t('Navbar.Targets') }}</b-card-title>
			
			<!-- Table modifiers -->
			<b-row align-h="end" class="mb-4">
				<b-col lg="8" md="12">
					<!-- Company selector -->
					<b-form-group :label="$t('Navbar.Company')" label-cols-sm="3" label-cols-md="4" label-cols-xl="5" label-align-sm="right" label-size="sm" 
						label-for="companyFilter" v-if="companyList.length>0">
						<b-input-group size="sm">
							<b-form-select id="companyFilter" size="sm" v-model="companyId" :options="companyList" @change="refreshData"></b-form-select>
						</b-input-group>
					</b-form-group>

					<!-- Target search -->
					<b-form-group :label="$t('General.Search')" label-cols-sm="3" label-cols-md="4" label-cols-xl="5" label-align-sm="right" label-size="sm" 
						label-for="filterInput">
						<b-input-group size="sm">
							<b-form-input v-model="filter" type="search" id="filterInput" debounce="500"
								:placeholder="$t('General.TypeToSearch')"></b-form-input>
							<template v-slot:append>
								<b-input-group-text class="icon-append">
									<font-awesome-icon class="vertical-align-middle" :icon="['fas', 'search']"/>
								</b-input-group-text>
							</template>
						</b-input-group>
					</b-form-group>

					<!-- Target filter -->
					<b-form-group :label="$t('General.Show')" label-cols-sm="3" label-cols-md="4" label-cols-xl="5" label-align-sm="right" label-size="sm" 
						label-for="targetFilter" v-if="companyList.length>0">
						<b-input-group size="sm">
							<b-form-select id="targetFilter" size="sm" v-model="filterType" :options="optionTargetList" @change="refreshData"></b-form-select>
						</b-input-group>
					</b-form-group>
				</b-col>
			</b-row>

			<!--  Target Table -->
			<b-table
				id="targetListTable"
				ref="targetListTable"
				:items="getCurrentTargetPage"
				:per-page="perPage"
				:current-page="currentPage"
				:fields="fields"
				:sort-by.sync="sortBy"
				:sort-desc.sync="sortDesc"
				:filter="filter"
				hover striped show-empty bordered no-sort-reset
				:emptyFilteredText="$t('General.TableFilterEmpty')"
				:empty-text="$t('General.TableEmpty')">

				<!-- Additional Template info for action column -->
				<template v-slot:cell(actions)="row">
					<div class="table-row-center">
						<div v-if="$checkPermission('delete-target')" class="action edit">
						<font-awesome-icon class="mx-2" :icon="['fas', 'trash-alt']"
							@click="confirmDeleteTarget(row.index, row.item)"/>
						</div>
						<div v-if="$checkPermission('modify-target')" class="action delete">
						<font-awesome-icon class="mx-2" :icon="['fas', 'pencil-alt']"
							@click="editTarget(row.index, row.item)"/>
						</div>
					</div>
				</template>
			</b-table>

			<!-- Pagination widget -->
			<b-row align-h="end">
				<b-col class="mb-2 d-block d-sm-block d-md-none">
					<label for="itemsPerPageSmall" class="text-right">{{ $t('Analytics.Table.ItemsPerPage') }}</label>
					<b-form-select id="itemsPerPageSmall" class="float-right" v-model="perPage" :options="pageSizeOptions"></b-form-select>
				</b-col>
				<b-col class="text-right pt-1 pr-0 d-none d-sm-none d-md-block">
					<label for="itemsPerPage" class="text-right">{{ $t('Analytics.Table.ItemsPerPage') }}</label>
				</b-col>
				<b-col cols="12" md="auto" class="mb-2 pr-0 d-none d-sm-none d-md-block">
					<!-- Items per Page widget -->
					<b-form-select id="itemsPerPage" class="float-right" v-model="perPage" :options="pageSizeOptions"></b-form-select>
				</b-col>

				<b-col cols="12" md="auto">
					<!-- Pagination widget -->
					<b-pagination v-model="currentPage" :total-rows="totalRows" :per-page="perPage" align="right"
						aria-controls="targetListTable" :disabled="perPage<1"></b-pagination>
				</b-col>
			</b-row>

			<b-row align-h="end">
				<b-col cols="auto" class="mb-3" v-if="$checkPermission('import-target')">
					<b-form-file
						v-model="targetsCsv"
						:placeholder="$t('Targets.ImportCsv')"
						accept=".csv"
						id="targetsfile"
						ref="targetsfile"
						:disabled="!companyId || !emailDomain"
						@input="checkCsvStructure"
						></b-form-file>
				</b-col>
				<b-col cols="auto" class="pl-0">
					<b-button :disabled="!companyId" variant="secondary" class="btn-action" :href="targetExport">{{ $t('Targets.Export') }}</b-button>
				</b-col>
				<b-col cols="auto">
					<b-button v-if="$checkPermission('create-target')" :disabled="!companyId || !emailDomain" variant="primary" class="btn-action" 
						@click="addNewTarget()">{{ $t('Targets.NewTarget') }}</b-button>
				</b-col>
			</b-row>
		</div>

		<!-- Targets import preview modal -->
		<div class="import-targets-modal">
			<b-modal id="import-target-modal" size="xl" ref="targetsModal" @hidden="resetImportValues" :title="$t('Targets.Import')">
				<p>{{$t('Targets.ImportInfo')}}</p>
				<b-row class="mb-4">
					<b-col cols="auto">
						<b-form-checkbox v-model="hasHeader" value="1" unchecked-value="0" @input="checkCsvStructure">
							{{$t('Targets.CsvHeader')}}
						</b-form-checkbox>
					</b-col>
					<b-col>
						<b-row align-h="end">
							<b-col cols="auto">
								<label for="separatorCharInput" class="separator-label">{{ $t('Targets.Separator') }}</label>
							</b-col>
							<b-col sm="4">
								<b-form-input id="separatorCharInput" :state="separatorSingleChar" required type="text" 
									v-model="separatorValue" @input="repeatParsing"></b-form-input>
								<b-form-invalid-feedback id="input-separator-feedback">
									{{ $t('Targets.SeparatorError') }}
								</b-form-invalid-feedback>
							</b-col>
						</b-row>
					</b-col>
				</b-row>
				
				<div class="mt-2" style="height: 50px;">
					<b-alert class="w-75 center-div" :show="showImportError" :variant="importErrorVariant">
						{{ errorAlertMsg }}
					</b-alert>
				</div>
				<table class="table table-striped table-bordered table-import mt-2">
					<thead>
						<th v-for="(column, key, index) in colPerRows" :key="index" :class="{'multimap-error':multipleAttributeMapping.includes(key)}">
							<b-form-select v-model="mappingCols[key]" @change="checkEmailValue" :options="colOptions" size="sm"></b-form-select>
						</th>
					</thead>
					<thead v-show="headerData.length>0">
						<th v-for="(data, key, index) in headerData" :key="index">
							{{data}}
						</th>
					</thead>
					<tbody>
						<tr v-for="(target, t) in targetsPreviewItems" :key="t" >
							<td v-for="(attr, j) in target" :key="j" :class="{'deleted-target-row': mappingCols[j] == 'deleted'}"> 
								{{attr}}
							</td>
						</tr>
					</tbody>
				</table>
				<span>{{ $t('Targets.ImportNumber', {num: csvRows}) }}</span>
				<b-row align-h="end" class="mx-0" v-if="$checkPermission('delete-target')">
					<b-col cols="auto">
						<b-form-group class="form-align-self-center mt-2 mb-0" :label="$t('Targets.ImportMode')" label-class="font-weight-bold">
							<b-form-radio-group
								id="targetImportMethod"
								v-model="targetHardImport"
								:options="methodOptions"
								name="target-import-options"
								stacked
							></b-form-radio-group>
						</b-form-group>
					</b-col>
				</b-row>
				<template v-slot:modal-footer>
					<div class="w-100">
						<b-button
							variant="primary"
							size="md"
							class="float-right ml-2"
							:disabled="!mappedEmail || multipleAttributeMapping.length>0 || !validEmailMapping || !validEmailDomain"
							@click="clickHandlerSubmit()"
						>
							{{ $t('Targets.Import') }}
						</b-button>						
					</div>
				</template>
			</b-modal>
			<b-modal id="remove-targets" content-class="shadow" size='md' buttonSize='md' okVariant='danger' 
				bodyClass="text-center" footerClass='p-2' :centered='true' :title="$t('Account.ConfirmDeleteTitle')" @ok="$bvModal.show('replace-import-confirm')">
				<p class="my-2 text-left" v-html="$t('Targets.ConfirmRemoveMesssage')"></p>
			</b-modal>
			<b-modal id="replace-import-confirm" content-class="shadow" size='md' buttonSize='md' okVariant='danger' 
				bodyClass="text-center"  @show="userHasConfirmed = ''" footerClass='p-2' :centered='true' 
				:okDisabled="userHasConfirmed != 'OK'" :title="$t('Account.ConfirmDeleteTitle')" @ok="replaceImportTargets">
				<p class="my-2 text-left" v-html="$t('General.ConfirmNotReversible')"></p>
				<input class="text-center mt-4" v-model="userHasConfirmed" />
			</b-modal>
		</div>

	</b-container>
</template>

<script>
import targetService from '../services/target.service';
import companyService from '../services/company.service';
import syspropService from '../services/sysprop.service';
import { TablePaginationSize } from "../../../server/common/constants";
import { isEmail } from 'validator';
import papa from 'papaparse';
import Vue from 'vue';

export default {
	name: "cg-targets",
	data() {
		return {
			perPage: 15,
			currentPage: 1,
			filter: null,
			totalRows: 1,
			newTarget: {},
			inProgress: false,
			alertVariant: "danger",
			alertMessage: "",
			alertParams: {},
			dismissSecs: 5,
			companyId: localStorage.getItem("cg-company"),
			sortBy: "firstname",
			sortDesc: false,
			fields: [],
			targetsCsv: null,
			totalCsvRows: 0,
			hasHeader: '0',
			colPerRows: 0,
			headerData: [],
			validEmailMapping: true,
			separatorValue: null,
			validEmailDomain: true,
			targetsPreviewItems: [],
			data: {
				name: "target-modal",
				title: 'Targets.NewTarget',
				label: 'Targets.NewTarget',
				item: {},
				submitHandler: "updateTarget",
				fields:[
					{   id: "firstname",
						label: 'Targets.FirstName',
						type: "text",
						display: true,
						required: false,
						placeholder: 'Targets.FirstName'
					},
					{   id: "lastname",
						label: 'Targets.LastName',
						type: "text",
						display: true,
						required: false,
						placeholder: 'Targets.LastName'
					},
					{   id: "nickname",
						label: 'Targets.Nickname',
						type: "text",
						display: true,
						required: false,
						placeholder: 'Targets.Nickname'
					},
					{   id: "city",
						label: 'Targets.City',
						type: "text",
						display: true,
						required: false,
						placeholder: 'Targets.City'
					},
					{   id: "site",
						label: 'Targets.Site',
						type: "text",
						display: true,
						required: false,
						placeholder: 'Targets.Site'
					},
					{	id: "country",
						label: 'General.Country',
						type: "select",
						display: true,
						required: false,
						options: [],
						placeholder: 'General.Country'
					},
					{   id: "language",
						label: 'Targets.Language',
						type: "select",
						display: true,
						required: false,
						options: [],
						placeholder: 'Targets.Language'
					},
					{   id: "email",
						label: 'Targets.Email',
						type: "lowercase",
						display: true,
						required: true,
						placeholder: 'Targets.Email',
						validator: this.isValidDomain,
						input_type: "email",
						errorAlertMessage: 'Targets.InvalidEmailDomain',
						errorAlertParams: this.getEmailDomainAlertParam
					},
					{   id: "telephone",
						label: 'Targets.Telephone',
						type: "text",
						display: true,
						required: false,
						placeholder: 'Targets.Telephone',
						validator: this.isValidTelephoneNumber,
					},
					{   id: "org_1",
						label: 'Targets.Org_1',
						type: "text",
						display: true,
						required: false,
						placeholder: 'Targets.Org_1'
					},
					{   id: "org_2",
						label: 'Targets.Org_2',
						type: "text",
						display: true,
						required: false,
						placeholder: 'Targets.Org_2'
					},
					{   id: "org_3",
						label: 'Targets.Org_3',
						type: "text",
						display: true,
						required: false,
						placeholder: 'Targets.Org_3'
					},
					{   id: "org_4",
						label: 'Targets.Org_4',
						type: "text",
						display: true,
						required: false,
						placeholder: 'Targets.Org_4'
					},
					{   id: "suspended",
						label: 'Targets.Suspended',
						type: "simple-checkbox",
						display: true,
						required: false,
						value: 1, 
						unchecked_value: 0
					}
				]
			},
			mappingCols: [],
			previewRows: 4,
			emailDomain: null,
			companyList: [],
			optionTargetList: [],
			filterType: 2,
			replace: false,
			userHasConfirmed: '',
			methodOptions: [],
			targetHardImport: null
		}
	},
	computed: {
		pageSizeOptions() {
			return TablePaginationSize || [];
		},
		showImportError() {
			return !this.mappedEmail || this.multipleAttributeMapping.length > 0 || !this.validEmailMapping || !this.validEmailDomain;
		},
		importErrorVariant() {
			return this.multipleAttributeMapping.length > 0 || !this.validEmailMapping  || !this.validEmailDomain? 'danger' : 'warning';
		},
		errorAlertMsg() {
			if(this.multipleAttributeMapping.length > 0) {
				return this.$i18n.t('Targets.MultipleAttributeMapping');
			}
			if(!this.validEmailMapping) {
				return this.$i18n.t('Targets.InvalidEmailMapping');
			}
			if(!this.validEmailDomain) {
				return this.$i18n.tc('Targets.InvalidEmailDomain', this.emailDomain.length, { domain: this.emailDomain.join(', ')});
			}
			if(!this.mappedEmail) {
				return this.$i18n.t('Targets.MapEmail');
			}
			return '';
		},
		separatorSingleChar() {
			return this.separatorValue? this.separatorValue.length == 1 : false;
		},
		colOptions() {
			var opt  = this.data.fields.map((field) => { return { value: field.id, text: this.$i18n.tc(field.label) }});
			opt.unshift({ value: null, text: '' });
			if(this.$checkPermission('delete-target')) {
				opt.push({ value: 'deleted', text: this.$i18n.t('Targets.Deleted') });
			}
			
			return opt;
		},
		mappedEmail() {
			return this.mappingCols.some((col) => col == 'email');
		},
		multipleAttributeMapping() {
			var multi = [];
			this.mappingCols.forEach((attr, i) => {
				let occure = this.mappingCols.filter((el) => { return el && el != "" && el == attr });
				if(occure.length > 1) {
					multi[i] = i;
				}
			})
			return multi.filter((el) => { return el != null; });
		},
		csvRows() {
			return this.totalCsvRows;
		},
		targetExport() {
			return `api/company/${this.companyId}/target/export`;
		}
	},
	methods: {
		repeatParsing() {
			if(this.separatorSingleChar) {
				this.checkCsvStructure();
			}
		},
		clickHandlerSubmit() {
			if (this.targetHardImport) {
				this.$bvModal.show('remove-targets');
			} else {
				this.appendImportTargets();
			}
		},
		async refreshData() {
			this.$refs.targetListTable.refresh();

			var res = await companyService.getCompany(this.companyId);
			if(res.data.email_domain) {
				this.emailDomain = res.data.email_domain.split(/,| /).filter(dom => { return  dom != ''});
			} else {
				this.alertVariant = 'warning';
				this.alertMessage = this.$i18n.t('Targets.NoEmailDomainFound');
				this.$refs.cgLocAlert.showAlert();
			}
		},
		updateTarget(newTarget) {
			this.inProgress = true;

			let targetPromise;
			let targetId = newTarget.target_id;

			if (targetId) {
				targetPromise = targetService.updateTarget(targetId, newTarget);
			} else {
				targetPromise = companyService.createTarget(this.companyId, newTarget);
			}

			Object.keys(newTarget).forEach((key) => {
				if(!newTarget[key]) {
					newTarget[key] = null;
				}
			});

			targetPromise.then((res) => {
				console.info("Target Management - updateTarget success", res);
				this.alertVariant = 'success';
				this.alertMessage = targetId ? 'Targets.TargetModified' : 'Targets.TargetAdded';

				// Refresh table to reload data
				this.$nextTick(() => {
					this.$refs.targetListTable.refresh();
				});
			}).catch((error) => {
				console.error("Target Management - updateTarget error", error);
				this.alertVariant = 'danger';
				if (error.response.status == 409) {
					this.alertMessage = 'Targets.TargetAlreadyExists';
					this.alertParams = { param: newTarget.email };
					this.dismissSecs = 30;
				} else {
					this.alertMessage = targetId ? 'Targets.TargetModifiedError' : 'Targets.TargetAddedError';
				} 
			}).finally(() => {
				this.inProgress = false;
				this.$refs.cgLocAlert.showAlert();
			});
		},
		resetImportValues() {
			this.targetsCsv = null;
			this.mappingCols, this.headerData = [];
			this.hasHeader = '0';
		},
		addNewTarget() {
			this.data.item = { language: null, country: null };
			this.data.title = 'Targets.NewTarget';
			this.data.label = 'Targets.NewTarget';
			
			this.$bvModal.show(this.data.name);
		},
		editTarget(index, record) {
			console.debug("Target Management - editTarget", index, record);
			this.data.item = record;
			this.data.title = 'Targets.EditTarget';
			this.data.label = 'General.Save';

			this.$bvModal.show(this.data.name);
		},
		confirmDeleteTitle() {
			return this.$i18n.t("Targets.ConfirmDeleteTitle");
		},
		confirmDeleteMessage(target) {
			return this.$i18n.t("Targets.ConfirmDeleteMessage", { param: target.lastname + ' ' + target.firstname });
		},
		confirmDeleteTarget(index, item) {
			// This function displays a modal for the user to confirm the deletion
			this.$bvModal.msgBoxConfirm(this.confirmDeleteMessage(item), {
				title: this.confirmDeleteTitle(),
				size: 'md',
				buttonSize: 'md',
				okVariant: 'danger',
				footerClass: 'p-2',
				hideHeaderClose: false,
				centered: true
			}).then(value => {
				// If the user confirms the deletion, value is not null
				if (value) {
					this.deleteTarget(index, item);
				}
			}).catch(err => {
				// The user canceled the action
				console.error("Targets - confirmDeleteTarget error", err);
			});
		},
		deleteTarget(index, item) {
			this.inProgress = true;
			var self = this;

			targetService.softDeleteTarget(item.target_id).then(function () {
				console.debug("Targets - deleteTarget success");
				self.alertVariant = 'success';
				self.alertMessage = 'Targets.TargetDeleted';
				// Refresh table to reload data
				self.$nextTick(() => {
					self.$refs.targetListTable.refresh();
				});
			}).catch(function (err) {
				console.error("Targets - deleteTarget error", err);
				self.alertVariant = 'danger';
				self.alertMessage = 'Targets.TargetDeleteError';
			}).finally(function () {
				self.inProgress = false;
				self.$refs.cgLocAlert.showAlert();
			});
		},
		getCurrentTargetPage(ctx) {
			this.inProgress = true;
			return companyService.getTargets(this.companyId, ctx.filter, ctx.currentPage, ctx.perPage, ctx.sortBy, ctx.sortDesc, this.filterType).then(result => {
				let items = result.data.targets;
				this.totalRows = result.data.count;
				
				return items || [];
			}).catch((error) => {
				console.log(error);
			}).finally(() => {
				this.inProgress = false;
			});
		},
		checkCsvStructure() {
			if(!this.targetsCsv) {
				return;
			}
			//this.targetsCsv = this.$refs.targetsfile.files[0];
			var hasHeader = this.hasHeader=='1';
			papa.parse(this.targetsCsv,
			{	
				delimiter: this.separatorValue? this.separatorValue : ';',
				skipEmptyLines: true,
				complete: (results) => {					
					this.totalCsvRows = results.data.length;
				}
			})
			var rowsPreview = (hasHeader) ? this.previewRows-1: this.previewRows;
			papa.parse(this.targetsCsv,
			{
				header: hasHeader,
				skipEmptyLines: true,
				preview: rowsPreview,
				// delimitersToGuess: [',', '|', ';', '\t'],
				delimiter: this.separatorValue? this.separatorValue : ';',
				complete: (results) => {
					if(hasHeader) {
						this.headerData = results.meta.fields.map(k => k.toLowerCase()); 
						this.colPerRows = this.headerData.length;
						this.headerData.forEach((attr, i) => {
							let res = this.colOptions.filter((opt) => { return opt.value == attr; });
							this.mappingCols[i] = (res[0]) ? res[0].value : null;
						});

						// Retrieve attribute list
						let firstRow = results.meta.fields || [];
						// Create empty object to fill result array
						const emptyModel = firstRow.reduce((acc, curr) => (acc[curr.toLowerCase()] = null, acc), {});
						
						this.targetsPreviewItems = [];
						this.separatorValue = results.meta.delimiter;

						results.data.forEach((p) => {
							let temp = {};
							// Cartesian product
							Object.keys(emptyModel).forEach((k) => {
								let key = k.charAt(0).toUpperCase() + k.slice(1);
								temp[key] = p[k] || p[key] || p[k.toUpperCase()] || emptyModel[key];
							});
							this.targetsPreviewItems.push(temp);
						});

						this.totalCsvRows--;
					} else {
						this.targetsPreviewItems = results.data;
						this.separatorValue = results.meta.delimiter;
						this.colPerRows = (this.targetsPreviewItems[0]) ? this.targetsPreviewItems[0].length : 0; 
						this.mappingCols = [];
						this.headerData = [];
					}
					this.checkEmailValue();
					this.$refs.targetsModal.show();
				}
			})
		},
		appendImportTargets(){
			this.replace = false;
			this.importTargets();
		},
		replaceImportTargets() {
			this.replace = true;
			this.importTargets();
		},
		async importTargets() {
			this.inProgress = true;
			try {
				let targetsFormData = new FormData();
				targetsFormData.append('targetsfile', this.targetsCsv);
				targetsFormData.append('params', JSON.stringify( { mapping: this.mappingCols, header: this.hasHeader=='1', separator: this.separatorValue, locale: this.$i18n.locale, replace: this.replace }));
				let result = await companyService.importTargets(this.companyId, targetsFormData);
				if(result.data.length == 0) {
					this.alertMessage = 'Targets.ImportSuccess';
					this.alertVariant = 'success';
					this.alertParams = { param: '' };
				} else {
					var invalidRows = result.data.map((skipped) => { return skipped.row });
					var invalidEmail = result.data.map((skipped) => { return skipped.invalidEmail });

					this.alertMessage = 'Targets.ImportSuccess';
					this.alertParams = { param: this.$i18n.tc('Targets.SkippedInvalidtargets', result.data.length, 
						{ rowNum: invalidRows.join(', '), invalidEmail: invalidEmail.join(', ') }) };
					this.alertVariant = 'warning';
					this.dismissSecs = 30;
				}
			} catch (error) {
				this.alertMessage = 'Targets.TargetsImportError';
				this.alertVariant = 'danger';
			} finally {
				this.inProgress = false;
				this.$refs.targetListTable.refresh();
				this.$refs.targetsModal.hide();
				this.$refs.cgLocAlert.showAlert();
				this.targetsCsv = null;
			}
		},
		async getCompanyOrgLabels() {
			let result = await companyService.getCompanyOrgLabels(this.companyId);
			if(result && result.data) {
				let orgs = result.data[0];
				Vue.set(this.fields.find(i => i.key === 'org_1'), 'label', (orgs && orgs.org_1) || this.$t('Targets.Org_1'));
				Vue.set(this.fields.find(i => i.key === 'org_2'), 'label', (orgs && orgs.org_2) || this.$t('Targets.Org_2'));
				Vue.set(this.fields.find(i => i.key === 'org_3'), 'label', (orgs && orgs.org_3) || this.$t('Targets.Org_3'));
				Vue.set(this.fields.find(i => i.key === 'org_4'), 'label', (orgs && orgs.org_4) || this.$t('Targets.Org_4'));
				Vue.set(this.data.fields.find(i => i.id === 'org_1'), 'label', (orgs && orgs.org_1) || this.$t('Targets.Org_1'));
				Vue.set(this.data.fields.find(i => i.id === 'org_2'), 'label', (orgs && orgs.org_2) || this.$t('Targets.Org_2'));
				Vue.set(this.data.fields.find(i => i.id === 'org_3'), 'label', (orgs && orgs.org_3) || this.$t('Targets.Org_3'));
				Vue.set(this.data.fields.find(i => i.id === 'org_4'), 'label', (orgs && orgs.org_4) || this.$t('Targets.Org_4'));
			}
		},
		checkEmailValue() {
			var emailIndex = this.mappingCols.indexOf('email');
			if(emailIndex == -1) {
				this.validEmailMapping = true;
			} else {
				if(this.headerData.length == 0) {
					this.validEmailMapping = this.targetsPreviewItems.every((target) => { return isEmail(target[emailIndex].trim()) });
					this.validEmailDomain = this.targetsPreviewItems.every((target) => { return this.isValidDomain(target[emailIndex].toLowerCase(), true) });
				} else {
					this.validEmailMapping = this.targetsPreviewItems.every((target) => { 
						var keys = Object.keys(target); 
						for(var i in keys) {
							if(i == emailIndex) {
								return isEmail(target[keys[i]].trim());
							}
						}									
					});
					this.validEmailDomain = this.targetsPreviewItems.every((target) => { 
						var keys = Object.keys(target); 
						for(var i in keys) {
							if(i == emailIndex) {
								return this.isValidDomain(target[keys[i]].toLowerCase(), true);
							}
						}									
					});
				}
			}
		},
		isValidDomain(email, isImport) {
			if(!email) return;

			let trimmeredEmail = email.trim();
			this.tempDomain = isImport? [...this.emailDomain, '@deletedtarget.xxx'] : this.emailDomain;

			return (trimmeredEmail && this.tempDomain && this.tempDomain.length > 0) ? this.tempDomain.includes(trimmeredEmail.substring(trimmeredEmail.indexOf('@'))) : true;
		},
		isValidTelephoneNumber(number) {
			return /^(\+)?([0-9]{1,24})$/.test(number) && number.length < 25;
		},
		getEmailDomainAlertParam() {
			return (this.emailDomain && this.emailDomain.length>0) ? { "domain" : this.emailDomain.join(', ')} : '';
		}
	},
	async created() {
		this.getCompanyOrgLabels();

		this.methodOptions = [
			{ value: null, text: this.$t('Targets.ImportAddText') },
			{ value: 1, text: this.$t('Targets.ImportRemoveText') }
		];

		this.fields = [
			{ key: "firstname", label: this.$t('Targets.FirstName'), sortable: true },
			{ key: "lastname", label: this.$t('Targets.LastName'), sortable: true },
			{ key: "nickname", label: this.$t('Targets.Nickname'), sortable: true, class: 'd-none d-xl-table-cell' },
			{ key: "email", label: this.$t('Targets.Email'), sortable: true },
			{ key: "org_1", label: this.$t('Targets.Org_1'), sortable: true, class: 'd-none d-md-table-cell' },
			{ key: "org_2", label: this.$t('Targets.Org_2'), sortable: true, class: 'd-none d-lg-table-cell'},
			{ key: "org_3", label: this.$t('Targets.Org_3'), sortable: true, class: 'd-none d-xl-table-cell' },
			{ key: "org_4", label: this.$t('Targets.Org_4'), sortable: true, class: 'd-none d-xl-table-cell' }
		];

		// Get all languages that appear in template content
		var templateLanguages = await companyService.getTemplateLanguages(this.companyId);
		var landingPageLanguages = await companyService.getLandingPageLanguages(this.companyId);
		
		// Get all system languages
		var appLanguages = await syspropService.getLanguages();

		// Extract from the language arrays the ISO code
		var tl = templateLanguages.data.map(lan => lan.language_id);
		var lpl = landingPageLanguages.data.map(lan => lan.language_id);
		var al = appLanguages.data.map(lan => lan.language_id);

		// Merge the result to obtain the target available language dropdown list
		var usedLanguages = [...new Set(tl.concat(al).concat(lpl))];

		// Retrieve locale settings
		let locale = this.$i18n.locale;

		// Create the following objects in the form { value, text } with value the ISO code and text the translation according user's locale
		let languages = [];
		usedLanguages.forEach((lan) => {
			if(this.$langs[lan]) {
				languages.push({ value: lan, text: this.$langs[lan] });
			}
		});

		languages.unshift({ value: null, text: this.$i18n.t('General.SelectOption') });
		Vue.set(this.data.fields.find(i => i.id === 'language'), 'options', languages);

		let supportedCountry = [];
		let countryList = await this.$isoCountriesLanguages.getCountries(locale);
		Object.keys(countryList).forEach((k) => {
			supportedCountry.push({ value: k, text: countryList[k] });
		});
		
		supportedCountry.unshift({ value: null, text: this.$i18n.t('General.SelectOption') });
		Vue.set(this.data.fields.find(i => i.id === 'country'), 'options', supportedCountry);

		// Enable this line to display 'site' field as a localized site dropdown list
		// Vue.set(this.data.fields.find(i => i.id === 'site'), 'options', this.$isoCountriesLanguages.getCountries(this.$i18n.locale));

		this.optionTargetList = [
			{ value: 2, text: this.$t('Targets.SuspendedAndActiveOption') },
			{ value: 0, text: this.$t('Targets.ActiveOption') },
			{ value: 1, text: this.$t('Targets.SuspendedOption') }
		];
 
		if(this.$checkPermission('modify-target') || this.$checkPermission('delete-target')) {
			this.fields.push({ key: "actions", label: this.$t('General.Actions'), class: 'text-center' });
		}
	},
	async mounted() {
		if(!this.companyId) {
			this.alertVariant = 'warning';
			this.alertMessage = this.$i18n.t('Targets.NoCompanyFound');
			this.$refs.cgLocAlert.showAlert();
		} else {
			if(this.$checkPermission('list-company')) {
				var companies = await companyService.getCompanyList();
				companies.data.forEach((company) => {
					this.companyList.push({ value: company.company_id, text: company.name });
				});
			}

			var res = await companyService.getCompany(this.companyId);
			if(res.data.email_domain) {
				this.emailDomain = res.data.email_domain.split(/,| /).filter( dom => { return  dom!=''} );
			} else {
				this.alertVariant = 'warning';
				this.alertMessage = this.$i18n.t('Targets.NoEmailDomainFound');
				this.$refs.cgLocAlert.showAlert();
			}
            console.debug("Targets view mounted -> this.emailDomain", this.emailDomain);
		}
	}
};
</script>

<style lang="less">
#target {
	.target-import {
		max-width: 500px;
	}
	.no-targets-imported {
		min-height: 200px;
	}
	
	.custom-file-label {
		padding-right: 76px;
		overflow: hidden;
		text-overflow: ellipsis;
		white-space: nowrap;
	}
	.custom-file {
		min-width: 400px;
	}
}
.table-import {
	margin-top: 20px;
}
.deleted-target-row{
	background-color:  rgba(255, 151, 18, 0.08);
}
#import-target-modal .modal-dialog {
	max-width: 95%;
	overflow: hidden;
	.multimap-error select {
		border: #dc3545 1px solid;
	}
	.separator-label {
		line-height: 38px;
		margin-bottom: 0;
	}
	.invalid-feedback {
		position: absolute;
	}
}
@media only screen and (min-width: 1199px) {
	.import-targets-modal .modal-dialog {
		max-width: 1200px;
	}
}
</style>